import { API_BASE, SHOPIFY_CONTROLLER, ADMIN_CONTROLLER } from "../constans";

const SHOPIFY_CONTROLLER_PATH = `${API_BASE}/${SHOPIFY_CONTROLLER}`;

export const updateStep = (shop, step) =>
    fetch(`${SHOPIFY_CONTROLLER_PATH}/UpdateLastUserStep?shop=${shop}&step=${step}`, { method: 'POST' });

export const confirmEmail = (shop, email, owner) =>
    fetch(`${SHOPIFY_CONTROLLER_PATH}/ConfirmEmail?shop=${shop}&email=${email}&owner=${owner}`, { method: 'POST' } );

export const sendSKUEmail = (shop, sku1, sku2) => 
    fetch(`${SHOPIFY_CONTROLLER_PATH}/SendSKUEmail?shop=${shop}`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            selectedSKUs: { sku1, sku2 },
        }),
    });

export const saveCTAConfig = (shop, reconfigure, config) => 
    fetch(`${SHOPIFY_CONTROLLER_PATH}/SaveCTA?shop=${shop}&reconfigure=${reconfigure}`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ config })
    });

const SEARCH_TYPES = {
    'bySKU': 'sku',
    'byTitle': 'title'
};

export const searchSKUs = (shop, value, searchType) => 
    fetch(`${SHOPIFY_CONTROLLER_PATH}/SearchProduct?shop=${shop}&searchTerm=${value}&searchType=${SEARCH_TYPES[searchType]}`, {
        method: 'POST'
    }).then((res) => res.json());

export const getCTAConfig = (shop) => 
    fetch(`${SHOPIFY_CONTROLLER_PATH}/GetCTAConfig?shop=${shop}`).then((response) => response.json());

export const contactUs = (shop, message) => 
    fetch(`${SHOPIFY_CONTROLLER_PATH}/ContactUs?shop=${shop}&message=${message}`, { method: 'POST' });

export const validateDiscount = (discount, plan) =>
    fetch(`${API_BASE}/${ADMIN_CONTROLLER}/ValidateDiscount?code=${discount}&plan=${plan}`).then((response) => response.json());    

    //uncomment To test fixed discount validation
    // Promise.resolve({
    //     data: {
    //         discountType: 'fixed',
    //         discountAmount: 100
    //     },
    //     status: 'ok'
    // });

    //uncomment To test percent discount validation
    // Promise.resolve({
    //     data: {
    //         discountType: 'percent',
    //         discountAmount: 10
    //     },
    //     status: 'ok'
    // });
    //uncomment To test failed discount validation
    // Promise.resolve({
    //     status: 'error',
    //     error: 'error'
    // });
    
