import React, { useContext } from 'react';
import { Page, Layout, DisplayText, Stack,  Button, ButtonGroup } from '@shopify/polaris';
import HelpModal from '../components/HelpModal';
// import { APP_NAME } from '../constans';
import { useNavigate } from 'react-router-dom';
import AppContext from '../AppContext';

const Error = () => {
    const { setError } = useContext(AppContext);
    const navigate = useNavigate();

    const handleReload = () => {
        setError(true);
        navigate('/dashboard')
    };
    return (
        <Page fullWidth>
            <Stack vertical={true} wrap={false}>
                <Stack.Item fill>
                    <Layout>
                        <Layout.Section>
                            <div className="Top-Padding--superLarge text-center">
                                <DisplayText size="extraLarge">
                                    Oops! Something went wrong
                                </DisplayText>
                                <div className="Top-Padding--large mw-480 mx-auto">
                                    We apologize for any inconvenience, and have alerted our team about this issue. Please try again later, or contact us if this issue persists.
                                </div>
                                <div className="Top-Padding--medium d-flex flex-center">
                                    <ButtonGroup>
                                        {/* {shop && <a
                                            className="Polaris-Button Polaris-Button--primary"
                                            href={`https://${shop}/admin/apps/${APP_NAME}`}
                                            target="_top"
                                        >
                                            Reload
                                        </a>} */}
                                        <Button primary onClick={handleReload}>Reload</Button>
                                        <Button outline external url="https://www.tangiblee.com/case-studies">Our Case Studies</Button>
                                    </ButtonGroup>
                                    {/* <HelpModal
                                        customActivator={(onClick) => <Button primary onClick={onClick}>Contact Us</Button>}
                                    /> */}
                                </div>
                            </div>
                        </Layout.Section>
                    </Layout>
                </Stack.Item>

                <Stack.Item>
                    <Layout>
                        <Layout.Section fullWidth>
                            <Stack distribution="center" alignment="center">
                                <div className="support">
                                    *Need help? &nbsp;
                                    <HelpModal />
                                </div>
                            </Stack>
                        </Layout.Section>
                    </Layout>
                </Stack.Item>
            </Stack>
        </Page>
    );
};

export default Error;
