import { FormLayout, Link, Modal, Stack, TextField, Thumbnail, Toast } from "@shopify/polaris";
import React, { useState, useCallback, useContext } from "react";
import AppContext from "../AppContext";
import logo from '../assets/profile_logo.png';
import { contactUs } from "../core/api";

function HelpModal({customActivator}) {
    const { shop } = useContext(AppContext);

    const [active, setActive] = useState(false);
    const [message, setMessage] = useState('');
    const [error, setError] = useState(false);

    const [showToast, setShowToast] = useState(false);
    const toggleToast = useCallback(() => setShowToast((showToast) => !showToast), []);
  
    const toastMarkup = showToast ? (
        <Toast content="Message sent" onDismiss={toggleToast} />
    ) : null;

    const sendMessage = useCallback(() => {
        if(!message || message.length < 30) {
            setError(true);
            return;
        }
        contactUs(shop, message);
        setActive(!active);
        setShowToast(true);
        setMessage('');
        setError(false);
    }, [shop, active, message]);

    const handleChange = useCallback(() => {
        setActive(!active);
        setShowToast(false);
    }, [active]);

    const handleMessageChange = useCallback((msg) => setMessage(msg), []);

    let activator = customActivator
        ? customActivator(handleChange)
        : <Link onClick={handleChange}>Contact Us</Link>;

    return (
        <>
            <Modal
                activator={activator}
                open={active}
                onClose={handleChange}
                title="Contact Tangiblee Support"
                primaryAction={{
                    content: "Send message",
                    onAction: sendMessage,
                }}
                secondaryActions={[
                    {
                        content: "Cancel",
                        onAction: handleChange,
                    },
                ]}
            >
                <Modal.Section>
                    <FormLayout>
                        <Stack alignment="center">
                            <Thumbnail
                                source={logo}
                                size="small"
                                alt="Tangiblee"
                            />
                            <span>Tangiblee - Virtual Try-On for Jewelry by Yruler</span>
                        </Stack>
                        {/* </FormLayout>
                    <FormLayout> */}
                        <TextField
                            label="Our Team will be able to address any questions or issues you have with the Tangiblee - Virtual Try-On app."
                            value={message}
                            onChange={handleMessageChange}
                            helpText={!error ? 'Minimum 30 characters' : false }
                            error={error ? 'Minimum 30 characters' : false}
                            minLength={30}
                            multiline={4}
                            autoComplete="off"
                            showCharacterCount
                        />
                    </FormLayout>

                </Modal.Section>
            </Modal>
            
            {toastMarkup}
            
        </>
        
    );
}

export default HelpModal;